// @flow
import React, { useState } from "react";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";

import { Loader } from "../../containers/TwoFactorAuth/SharedComponents";
// import "./annotation.css";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true
};

type Props = {
  file: string,
  onError: () => void
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const DocPreview = ({ file, wrapperDivSize = 0, onError }: Props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const renderLoading = () => {
    return <Loader />;
  };

  return (
    <Container>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
        loading={renderLoading}
        externalLinkTarget="_blank"
        onLoadError={onError}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            renderTextLayer={false}
            loding=""
            className="page-style"
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={wrapperDivSize}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    </Container>
  );
};

export default DocPreview;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgb(82, 86, 89);
  /* height: 100%; */
  min-height: 100%;
  user-select: none;

  input,
  button {
    font: inherit;
  }

  .page-style {
    background-color: #fff;
    max-width: calc(~"100% - 2em");
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 1em;
    canvas {
      max-width: 100%;
      height: auto !important;
    }
  }

  &__container {
    &__load {
      margin-top: 1em;
      color: white;
    }

    .react-pdf {
      &__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__Page {
        canvas {
          max-width: 100%;
          height: auto !important;
        }
      }

      &__message {
        padding: 20px;
        color: white;
      }
    }
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;
