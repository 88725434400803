// @flow

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import Modal, { CloseModalIcon } from "../Modal";
import * as Style from "./styledComponents";

import { styledSpanTagConverter } from "../../lib/componentifyConverters";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import { prepUrl } from "../../api/utils";

import PreviewDocModal from "./PreviewDocModal";
import DocPreview from "../DocumentViewer/DocPreview";
import PreviewDocErrorModal from "./PreviewDocErrorModal";

const customConverter = Object.assign({}, styledSpanTagConverter, {
  props: {
    style: {
      color: "#17bed0",
      paddingLeft: "5px"
    }
  }
});

type Props = {
  data: object,
  code: String,
  method: string,
  params: object,
  candownload: boolean,
  offsetX: string,
  offsetY: string,
  downloadmethod: string,
  downloadparams: string,
  prevplcCss: object
};

const PreviewDocumentWrapper = ({
  data,
  code,
  method,
  params,
  candownload,
  offsetX,
  offsetY,
  downloadmethod,
  downloadparams,
  prevplcCss
}: Props) => {
  const [displayPreviewDocModal, setDisplayPreviewDocModal] = useState(false);
  const [displayDocPreview, setDisplayDocPreview] = useState(false);
  const [displayPreviewDocErrorModal, setDisplayPreviewDocErrorModal] = useState(false);
  const [passwordProtected, setPasswordProtected] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [wrapperDivSize, setWrapperDivSize] = useState(0);
  const wrapperEl = useRef();

  useEffect(() => {
    apiMethod(
      method,
      params,
      ret => {
        let iframeSrcU = prepUrl(ret) + "?_=" + new Date().getTime();
        setIframeSrc(iframeSrcU);
        console.log("iframe", iframeSrcU, iframeSrc);

        $.ajax({
          url: iframeSrcU,
          cache: false,
          method: "HEAD",
          complete: () => {}
        })
          .done(() => {
            console.log("iframe done", iframeSrcU, iframeSrc);
            if (candownload) {
              setDisplayPreviewDocModal(true);
            } else {
              //render DocPreview
              setDisplayDocPreview(true);
            }
          })
          .fail(xhr => {
            console.log("iframe fail", iframeSrcU, iframeSrc);
            if (xhr.status === 403) {
              setPasswordProtected(true);
            }
            setDisplayPreviewDocErrorModal(true);
          });
      },
      {
        errorCallback: () => {
          setDisplayPreviewDocErrorModal(true);
        }
      }
    );
  }, [method, params]); //[method, params]

  useEffect(() => {
    if (displayDocPreview && wrapperEl.current) {
      setWrapperDivSize(wrapperEl.current.offsetWidth);
    }
  }, [displayDocPreview])

  const handleClose = () => {
    setIsOpen(false);
  };

  const onErrorPreview = () => {
    setDisplayPreviewDocModal(false);
    displayDocPreview(false);
    setDisplayPreviewDocErrorModal(true);
  };

  if (displayPreviewDocModal) {
    return (
      <PreviewDocModal
        data={data}
        documentName={data.name}
        iframeSrc={iframeSrc}
        candownload={candownload}
        code={code}
        downloadmethod={downloadmethod}
        downloadparams={downloadparams}
        offsetX={offsetX}
        offsetY={offsetY}
        prevplcCss={prevplcCss}
      />
    );
  } else if (displayDocPreview) {
    return (
      <Modal shouldCloseOnOverlayClick={false} animate onClose={handleClose} opened={isOpen}>
        <ModalContainer offsetX={offsetX} offsetY={offsetY}>
          <CloseModalIcon onClick={handleClose} />
          <ContainerW offsetX={offsetX} offsetY={offsetY}>
            <DocTitle>
              <Componentify
                text={`${__("Preview Document", "Preview Document")}<span>"${data.name}"</span>`}
                converters={[customConverter]}
              />
            </DocTitle>
            <DocPreviewWrapper ref={wrapperEl} offsetY={offsetY}>
              <DocPreview file={iframeSrc} onError={onErrorPreview} wrapperDivSize={wrapperDivSize}/>
            </DocPreviewWrapper>
          </ContainerW>
        </ModalContainer>
      </Modal>
    );
  } else if (displayPreviewDocErrorModal) {
    return (
      <PreviewDocErrorModal
        documentName={data.name}
        passwordProtected={passwordProtected}
        candownload={candownload}
        downloadmethod={downloadmethod}
        downloadparams={downloadparams}
      />
    );
  } else {
    return null;
  }
};

export default PreviewDocumentWrapper;

const ContainerW = styled(Style.Container)`
  width: ${props => props.offsetX}px;
`;

const DocTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
`;

const ModalContainer = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.offsetX}px;
  height: ${props => props.offsetY}px;
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
  transform-origin: ${props => props.mouseOffset};
`;

const DocPreviewWrapper = styled.div`
  margin-top: 20px;
  height: ${props => props.offsetY - 100}px;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
`;
